import type { Client } from '@urql/vue';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return; // Ensures this runs only in the browser

  const id = to.params.id;
  const $apiClient = useNuxtApp().$apiClient as Client;
  const result = await $apiClient.query(`
    query PublicationDraft($id: ID!) {
      publicationDraft(id: $id) {
        id
      }
    }
  `, { id }).toPromise();
  const data = result.data?.publicationDraft;
  if (result.error) {
    sentryError(`There was an error fetching the publicationDraft #${id} in the middleware`, result.error);
    return navigateTo('/500');
  } else if (!data?.id) {
    return navigateTo('/404'); // Redirect to 400 if the publicationDraft don't exist
  }
});
